<template>
  <ion-page>
    <Header title="Main" logo/>
    <ion-content :fullscreen="true">

      <ion-segment scrollable swipeGesture @ionChange="segmentChanged($event)" value="All">
        <ion-segment-button class="category-item" v-for="(category, index) in categories" :key="index"
                            :value="category">
          <ion-label>{{ category }}</ion-label>
        </ion-segment-button>
      </ion-segment>

      <!-- Slider Section -->
      <ion-grid class="featured-post-section">
        <ion-row>
          <ion-label class="section-title">Featured posts</ion-label>
        </ion-row>

        <ion-row>
          <ion-slides :pager="true" :options="slideOpts">
            <ion-slide v-for="(sliderArticle, index) in articleCollectionWithThumbnail" :key="index">
              <ArticleThumbnailCard :only-category-display="false"
                                    :title="sliderArticle.title"
                                    :category="sliderArticle.category"
                                    :read-time="sliderArticle.readTime"
                                    :viewed="sliderArticle.viewed"
                                    :image="sliderArticle.image"/>
            </ion-slide>
          </ion-slides>
        </ion-row>
      </ion-grid>

      <!-- Banner Section -->
      <ion-grid class="banner-ad-section">
        <ion-img :src="require('@/assets/images/banner-home-demo.png')"></ion-img>
        <ion-label>banner 60px height</ion-label>
      </ion-grid>

      <!-- Article Chunk 01 Section -->
      <ion-grid class="two-row-section">
        <ion-row v-for="(articleChunk, index) in articleCollection" :key="index" :class="`row${index+1}`">
          <ion-col v-for="(art, i) in articleChunk" :key="`${index}${i}`">
            <ArticleCardWithOutlineInfo :title="art.title"
                                        :category="art.category"
                                        :read-time="art.readTime"
                                        :viewed="art.viewed"
                                        :image="art.image"/>
          </ion-col>
        </ion-row>
      </ion-grid>

      <!-- Latest Article Section-->
      <ion-grid class="latest-article-section">
        <ion-row>
          <ion-label class="section-title">Meistgelesene Artikel</ion-label>
        </ion-row>
        <ion-row v-for="(latestArticle, index) in latestArticles" :key="index" class="article-row">
          <TitleWithInfo :title="latestArticle.title" :viewed="latestArticle.viewed"/>
        </ion-row>
      </ion-grid>

      <!-- Article Chunk 02 Section -->
      <ion-grid class="two-row-section" style="padding-top: 20px !important;">
        <ion-row v-for="(articleChunk, index) in articleCollection" :key="index" :class="`row${index+1}`">
          <ion-col v-for="(art, i) in articleChunk" :key="`${index}${i}`">
            <ArticleCardWithOutlineInfo :title="art.title"
                                        :category="art.category"
                                        :read-time="art.readTime"
                                        :viewed="art.viewed"
                                        :image="art.image"/>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import Header from "@/layouts/Header.vue";
import ArticleThumbnailCard from "@/components/cards/ArticleThumbnailCard.vue"
import ArticleCardWithOutlineInfo from "@/components/cards/ArticleCardWithOutlineInfo.vue"
import TitleWithInfo from "@/components/cards/partials/TitleWithInfo.vue";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonLabel,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSlide,
  IonSlides,
} from '@ionic/vue';
import {LatestArticle, ThumbnailArticleInterface, ThumbnailArticleWithChunkInterface} from "@/interfaces/Article";
/* Store */
import store from "@/store";

export default {
  name: 'Main',
  components: {
    Header,
    IonContent,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    IonSlides,
    IonSlide,
    IonImg,
    ArticleThumbnailCard,
    ArticleCardWithOutlineInfo,
    TitleWithInfo
  },
  setup: () => {
    const categories: Array<string> = store.state.categories
    const articleCollectionWithThumbnail: ThumbnailArticleInterface[] = store.state.articles;
    const segmentChanged = (event: CustomEvent) => {
      console.log(event.detail.value)
    }
    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      autoplay: {
        delay: 4000
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    };

    const articleCollection: ThumbnailArticleWithChunkInterface = [
      [articleCollectionWithThumbnail[3]],
      [articleCollectionWithThumbnail[2], articleCollectionWithThumbnail[1]]
    ];

    const latestArticles: Array<LatestArticle> = store.state.latestArticles;

    return {
      segmentChanged,
      categories,
      slideOpts,
      articleCollectionWithThumbnail,
      articleCollection,
      latestArticles,
    }
  }
}
</script>
