
import Header from "@/layouts/Header.vue";
import ArticleThumbnailCard from "@/components/cards/ArticleThumbnailCard.vue"
import ArticleCardWithOutlineInfo from "@/components/cards/ArticleCardWithOutlineInfo.vue"
import TitleWithInfo from "@/components/cards/partials/TitleWithInfo.vue";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonLabel,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSlide,
  IonSlides,
} from '@ionic/vue';
import {LatestArticle, ThumbnailArticleInterface, ThumbnailArticleWithChunkInterface} from "@/interfaces/Article";
/* Store */
import store from "@/store";

export default {
  name: 'Main',
  components: {
    Header,
    IonContent,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    IonSlides,
    IonSlide,
    IonImg,
    ArticleThumbnailCard,
    ArticleCardWithOutlineInfo,
    TitleWithInfo
  },
  setup: () => {
    const categories: Array<string> = store.state.categories
    const articleCollectionWithThumbnail: ThumbnailArticleInterface[] = store.state.articles;
    const segmentChanged = (event: CustomEvent) => {
      console.log(event.detail.value)
    }
    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      autoplay: {
        delay: 4000
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    };

    const articleCollection: ThumbnailArticleWithChunkInterface = [
      [articleCollectionWithThumbnail[3]],
      [articleCollectionWithThumbnail[2], articleCollectionWithThumbnail[1]]
    ];

    const latestArticles: Array<LatestArticle> = store.state.latestArticles;

    return {
      segmentChanged,
      categories,
      slideOpts,
      articleCollectionWithThumbnail,
      articleCollection,
      latestArticles,
    }
  }
}
