<template>
  <ion-card
      class="article-card"
      :style="{
        background: `url('${image}'), linear-gradient(360deg, #29304D 0%, rgba(41, 48, 77, 0) 64.38%)`,
        backgroundSize: 'cover',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundBlendMode: 'overlay',
        backgroundRepeat: 'no-repeat'
      }"
  >
    <ion-grid class="card-header-section">
      <BackButtonIcon v-if="includeBackButton" class="back-button" @click="$router.back()"/>
      <HeartIcon class="heart-icon-area"/>
      <ion-row>
        <ion-button class="button-general danger text-uppercase">{{ category.name }}</ion-button>
      </ion-row>

      <TitleWithInfo v-if="!onlyCategoryDisplay" :title="title" :read-time="readTime" :viewed="viewed"/>
    </ion-grid>
  </ion-card>
</template>

<script>
import {IonButton, IonCard, IonGrid, IonRow} from "@ionic/vue";
import {eyeOutline, timeOutline} from "ionicons/icons";
import TitleWithInfo from "@/components/cards/partials/TitleWithInfo";
import HeartIcon from "@/components/icons/HeartIcon";
import BackButtonIcon from "@/components/icons/BackButtonIcon";

export default {
  name: "ArticleThumbnailCard",
  components: {IonCard, IonRow, TitleWithInfo, IonGrid, IonButton, HeartIcon, BackButtonIcon},
  props: {
    onlyCategoryDisplay: {
      type: Boolean,
      default: true
    },
    includeBackButton: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      required: true,
      validator: propValue => {
        const listOfAvailableExt = [".jpeg", ".jpg", ".png"];
        return listOfAvailableExt.some(ext =>
            propValue.endsWith(ext)
        );
      }
    },
    title: {
      type: String,
      required: false
    },
    category: {
      type: Object,
      required: true
    },
    readTime: {
      type: String,
      required: false
    },
    viewed: {
      type: Number,
      required: false
    }
  },
  setup() {
    return {
      timeOutline,
      eyeOutline
    }
  }
}
</script>
