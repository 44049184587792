<template>
  <div>
    <ion-row v-if="title" class="article-title" @click="$router.push('/article-details')">
      {{ title }}
    </ion-row>
    <ion-row class="info-section">
      <ion-col v-if="readTime" size="auto" class="read-time-section">
        <ion-icon :icon="timeOutline"/>
        <span>{{ readTime }}</span>
      </ion-col>

      <ion-col v-if="viewed" size="auto" class="viewed-section">
        <ion-icon :icon="eyeOutline"/>
        <span>{{ viewed }}</span>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import {IonCol, IonIcon, IonRow} from "@ionic/vue";
import {eyeOutline, timeOutline} from "ionicons/icons";

export default {
  name: "TitleWithInfo",
  components: {IonRow, IonCol, IonIcon},
  props: {
    title: {
      type: String,
      required: false
    },
    readTime: {
      type: String,
      required: false
    },
    viewed: {
      type: Number,
      required: true
    }
  },
  setup() {
    return {
      eyeOutline, timeOutline
    }
  }
}
</script>
