<template>
  <ion-grid>
    <ion-row class="article-card-with-outline-info">
      <ArticleThumbnailCard :only-category-display="true"
                            :title="title"
                            :category="category"
                            :read-time="readTime"
                            :viewed="viewed"
                            :image="image"/>
    </ion-row>

    <ion-row class="outline-info">
      <TitleWithInfo :title="title" :read-time="readTime" :viewed="viewed"/>
    </ion-row>
  </ion-grid>
</template>

<script>
import ArticleThumbnailCard from "@/components/cards/ArticleThumbnailCard";
import {IonGrid, IonRow} from '@ionic/vue';
import TitleWithInfo from "@/components/cards/partials/TitleWithInfo";

export default {
  name: "TwoRowArticlesCard",
  components: {TitleWithInfo, ArticleThumbnailCard, IonGrid, IonRow},
  props: {
    image: {
      type: String,
      required: true,
      validator: propValue => {
        const listOfAvailableExt = [".jpeg", ".jpg", ".png"];
        return listOfAvailableExt.some(ext =>
            propValue.endsWith(ext)
        );
      }
    },
    title: {
      type: String,
      required: false
    },
    category: {
      type: Object,
      required: true
    },
    readTime: {
      type: String,
      required: false
    },
    viewed: {
      type: Number,
      required: false
    }
  },
}
</script>

<style scoped>

</style>
