<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind="$attrs">
    <circle opacity="0.3" cx="20" cy="20" r="20" fill="#29304D"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M15.6329 20L25 29.2134L23.1836 31L12 20L23.1836 9L25 10.7866L15.6329 20Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "BackButtonIcon"
}
</script>

<style scoped>

</style>
