<template>
  <ion-header class="header">
    <ion-toolbar class="toolbar">
      <ion-title class="title" v-if="!logo">{{ title }}</ion-title>
      <ion-title class="title" v-else>
        <Logo/>
      </ion-title>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import Logo from "@/components/icons/Logo";
import {IonHeader, IonTitle, IonToolbar} from "@ionic/vue"

export default {
  name: "Header",
  props: {
    title: {
      type: String,
      required: true
    },
    logo: {
      type: Boolean,
      required: false
    }
  },
  components: {IonHeader, IonToolbar, IonTitle, Logo}
}
</script>

