<template>
  <div v-bind="$attrs"
       :style="{background: includeBg ? `url('${require('@/assets/heart-background.png')}')` : 'transparent'}">
    <svg v-if="marked" width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M20.2595 1.73305C19.1672 0.624418 17.6744 0 16.1163 0C14.5583 0 13.0655 0.624418 11.9732 1.73305L10.9981 2.70601L10.0231 1.73305C8.93076 0.624418 7.43799 0 5.87993 0C4.32188 0 2.82911 0.624418 1.73681 1.73305C0.625771 2.82299 0 4.31253 0 5.86722C0 7.4219 0.625771 8.91144 1.73681 10.0014L10.9981 19.25L20.2595 10.0087C21.3728 8.91849 22 7.42737 22 5.87088C22 4.31438 21.3728 2.82326 20.2595 1.73305Z"
            fill="#8CD8E5"/>
    </svg>
    <svg v-else width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M21.2595 2.73305C20.1672 1.62442 18.6744 1 17.1163 1C15.5583 1 14.0655 1.62442 12.9732 2.73305L11.9981 3.70601L11.0231 2.73305C9.93076 1.62442 8.43799 1 6.87993 1C5.32188 1 3.82911 1.62442 2.73681 2.73305C1.62577 3.82299 1 5.31253 1 6.86722C1 8.4219 1.62577 9.91144 2.73681 11.0014L11.9981 20.25L21.2595 11.0087C22.3728 9.91849 23 8.42737 23 6.87088C23 5.31438 22.3728 3.82326 21.2595 2.73305Z"
            stroke="white" stroke-width="2"/>
    </svg>
  </div>

</template>
<script>
export default {
  name: "HeartIcon",
  props: {
    marked: {
      type: Boolean,
      default: false
    },
    includeBg: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>

</style>
